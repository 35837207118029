import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe, registerLocaleData } from '@angular/common';
import { ApplicationRef, Inject, Injectable, OnDestroy } from '@angular/core';
import * as Get from '@npm-libs/ng-getx';
import {
  isNonNullable,
  isSettledPromiseFulfilled,
  isSettledPromiseRejected,
  loadDynamicJS,
  transformDefaultExportToReturn,
} from '@rcg/utils';
import { L10n, cldrData, loadCldr, setCulture, setCurrencyCode } from '@syncfusion/ej2-base';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  retry,
  startWith,
  switchMap,
} from 'rxjs';
import { allLanguagesQuery, availableLanguagesQuery, defaultLanguageQuery, translationsUpdateQuery } from '../gql/intl.gql';
import { INTL_OPTIONS, IntlOptions } from '../options-injection-token';

import defaultSfLocaleData from '@syncfusion/ej2-locale/src/en-US.json';
import * as defaultCldrCaGregorian from 'cldr-data/main/sl/ca-gregorian.json';
import * as defaultCldrNumbers from 'cldr-data/main/sl/numbers.json';
import * as defaultCldrTimeZoneNames from 'cldr-data/main/sl/timeZoneNames.json';
import * as supplementalCldrNumberingSystems from 'cldr-data/supplemental/numberingSystems.json';

interface Translations {
  [name: string]: string;
}

interface SavedTranslation {
  savedAt: string;
  translations: Translations;
}

interface SavedTranslations {
  [locale: string]: SavedTranslation;
}

interface AvailableLanguage {
  code: string;
  name: string;
  angular_code: string;
  cldr_code: string;
}

type SfLocalizations = Record<string, string>;
type SfLocale = Record<string, SfLocalizations>;

//? Expose private variable
const L10nLoc = L10n as unknown as L10n & { locale: SfLocale };

const typedCldrData = cldrData as {
  main: Record<string, unknown>;
  default: {
    main: Record<string, unknown>;
  };
};

const ngPipeTransformations = [
  [CurrencyPipe, CurrencyPipe.prototype.transform, 4],
  [DatePipe, DatePipe.prototype.transform, 3],
  [DecimalPipe, DecimalPipe.prototype.transform, 2],
  [PercentPipe, PercentPipe.prototype.transform, 2],
] as const;

const translationsLocalStorageKey = 'rcgNgTranslations';

const defaultLocale = 'sl_SI';
const defaultAngularLocale = 'sl';
const defaultSyncfusionLocale = Object.keys(defaultSfLocaleData)[0];

const defaultCldrLocale = 'sl';

loadCldr(supplementalCldrNumberingSystems);
loadCldr(defaultCldrCaGregorian, defaultCldrNumbers, defaultCldrTimeZoneNames);

setCulture(defaultCldrLocale);
setCurrencyCode('EUR');

L10n.load(defaultSfLocaleData);

let sfLocaleIndex = 0;

export function tr(name: string, params?: { [name: string]: unknown }) {
  return IntlService.instance$.pipe(
    switchMap((is) => is.getTranslation(name, params)),
    map((t) => (t ? t : name)),
  );
}

@Get.NgAutoDispose
@Injectable({
  providedIn: 'root',
})
export class IntlService implements OnDestroy {
  private static _instance$ = new ReplaySubject<IntlService>(1);

  public static get instance$(): Observable<IntlService> {
    return IntlService._instance$;
  }

  private readonly localeR = new Get.Rx<string>(defaultLocale);
  public readonly locale$ = this.localeR.value$;

  private readonly angularLocaleR = new Get.Rx<string>(defaultAngularLocale, [distinctUntilChanged()]);
  private readonly cldrLocaleR = new Get.Rx<string>(defaultCldrLocale, [distinctUntilChanged()]);

  private readonly syncfusionLocaleR = new Get.Rx<string>(defaultSyncfusionLocale, [distinctUntilChanged()]);
  public readonly syncfusionLocale$ = this.syncfusionLocaleR.value$;

  private readonly introspectionEnabledR = new Get.Rx(false);
  public readonly introspectionEnabled$ = this.introspectionEnabledR.value$;

  private readonly availableLanguagesR = new Get.Rx<AvailableLanguage[]>([]);
  public readonly availableLanguages$ = this.availableLanguagesR.value$;

  private readonly savedTranslationsR = new Get.Rx<SavedTranslations>(
    JSON.parse(window.localStorage.getItem(translationsLocalStorageKey) ?? '{}') ?? {},
  );

  private readonly savedTranslationR = new Get.Rx<(SavedTranslation & { locale: string }) | undefined>(undefined);

  private tenantDefaultLangSwitchSub?: Subscription;

  constructor(@Inject(INTL_OPTIONS) private options: IntlOptions, private applicationRef: ApplicationRef) {
    IntlService._instance$.next(this);

    this.angularLocaleR.value$.subscribe((locale) => this.setNgLocale(locale));

    this.angularLocaleR.subscribeTo(
      combineLatest([this.localeR.value$, this.availableLanguagesR.value$]).pipe(
        map(([locale, availableLanguages]) => {
          return availableLanguages.find((l) => l.code === locale)?.angular_code ?? defaultAngularLocale;
        }),
      ),
    );

    this.cldrLocaleR.subscribeTo(
      combineLatest([this.localeR.value$, this.availableLanguagesR.value$]).pipe(
        map(([locale, availableLanguages]) => {
          return availableLanguages.find((l) => l.code === locale)?.cldr_code ?? defaultCldrLocale;
        }),
      ),
    );

    this.localeR.subscribeTo(this.options.locale$);

    this.availableLanguagesR.subscribeTo(
      this.options.tenantId$.pipe(
        switchMap((tenantId) =>
          this.options.query<{ data?: AvailableLanguage[] }>({
            query: tenantId ? availableLanguagesQuery : allLanguagesQuery,
            variables: tenantId ? { tenantId } : {},
          }),
        ),
        map((r) => r.data ?? []),
      ),
    );

    this.savedTranslationR.subscribeTo(
      combineLatest([this.localeR.value$, this.savedTranslationsR.value$]).pipe(
        map(([locale, savedTranslations]) => ({ ...savedTranslations[locale], locale })),
      ),
    );

    this.localeR.value$.subscribe({
      next: (l) => options.setLocale(l),
    });

    this.savedTranslationsR.value$.subscribe({
      next: (t) => window.localStorage.setItem(translationsLocalStorageKey, JSON.stringify(t)),
    });

    this.savedTranslationsR.subscribeTo(
      combineLatest([this.localeR.value$, this.savedTranslationR.value$]).pipe(
        switchMap(([locale, savedTranslation]) => {
          return this.options
            .query<{
              now: { timestamp: string }[];
              intl_languages: {
                translations: { name: string; translation: string }[];
                deleted_translations: { name: string }[];
              }[];
            }>({
              query: translationsUpdateQuery,
              variables: {
                locale,
                since: savedTranslation?.savedAt ?? '1970-01-01',
              },
            })
            .pipe(map((data) => ({ locale, data })));
        }),
        switchMap((res) => {
          const lang = res.data.intl_languages?.[0];
          if (!lang) return EMPTY;
          if (!lang.translations.length && !lang.deleted_translations.length) return EMPTY;

          return of(res);
        }),
        map(({ data, ...rest }) => {
          const { now, intl_languages, ...dataRest } = data;
          const nowTs = now.length ? now[0].timestamp : '1970-01-01';
          const langs = intl_languages.length ? intl_languages : null;

          return {
            ...rest,
            ...dataRest,
            now: nowTs,
            translations: langs?.[0].translations ?? [],
            deleted_translations: langs?.[0].deleted_translations ?? [],
          };
        }),
        map(({ translations, deleted_translations, ...rest }) => ({
          ...rest,
          translations: translations
            .map(({ name, translation }) => ({
              [name]: translation,
            }))
            .reduce((a, b) => ({ ...a, ...b }), {}),
          deleted_translations: deleted_translations.map((dt) => dt.name),
        })),
        map(({ now, locale, translations, deleted_translations }) => {
          const savedTranslations = this.savedTranslationsR.value;
          const localeSavedTranslations = savedTranslations[locale] as SavedTranslation | undefined;

          return {
            ...savedTranslations,
            [locale]: {
              savedAt: now,
              translations: {
                ...Object.fromEntries(
                  Object.entries(localeSavedTranslations?.translations ?? {}).filter(([k]) => !deleted_translations.includes(k)),
                ),
                ...translations,
              },
            },
          };
        }),
      ),
    );

    combineLatest([
      this.savedTranslationR.value$.pipe(startWith(this.savedTranslationR.value), filter(isNonNullable)),
      this.cldrLocaleR.value$.pipe(startWith(this.cldrLocaleR.value)),
    ])
      .pipe(
        debounceTime(1),
        map(([{ locale, translations }, cldrLocale]) => ({ locale, cldrLocale, translations })),
        distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c)),
        map(({ cldrLocale, translations }) => {
          try {
            const sfLocalizations = JSON.parse(translations['syncfusion']);

            return {
              cldrLocale,
              sfLocalizations: typeof sfLocalizations === 'object' && sfLocalizations ? (sfLocalizations as SfLocalizations) : {},
            };
          } catch (error) {
            return {
              cldrLocale,
              sfLocalizations: {},
            };
          }
        }),
        distinctUntilChanged((p, c) => JSON.stringify(p) === JSON.stringify(c)),
      )
      .subscribe(({ cldrLocale, sfLocalizations }) => {
        this.updateSfLocalizations(sfLocaleIndex++, cldrLocale, sfLocalizations);
      });

    const defaultLang$ = this.options.tenantId$.pipe(
      switchMap((tenantId) => {
        if (!tenantId) return of(undefined);

        return this.options
          .query<{ data: { default_language: { code: string } } }>({
            query: defaultLanguageQuery,
            variables: { tenantId },
          })
          .pipe(map((r) => r.data.default_language));
      }),
    );
    this.tenantDefaultLangSwitchSub = combineLatest([this.availableLanguages$, this.locale$, defaultLang$])
      .pipe(
        debounceTime(1),
        retry({
          delay: (error, retryCount: number) => {
            console.warn('tenantDefaultLangSwitchSub:', error, retryCount);
            return of(500);
          },
        }),
      )
      .subscribe(([al, locale, defaultLang]) => {
        if (defaultLang && !al.find((l) => l.code === locale)) {
          this.switchLocale(defaultLang.code);
        }
      });
  }

  ngOnDestroy(): void {
    this.tenantDefaultLangSwitchSub?.unsubscribe();
  }

  private async updateSfLocalizations(localeIndex: number, cldrLocale: string, sfLocalizations: SfLocalizations) {
    const rcgIndexedLocale = `RcG_${localeIndex}`;

    L10n.load({
      [rcgIndexedLocale]: sfLocalizations,
    });

    await this.importCldrData(rcgIndexedLocale, [
      `main/${cldrLocale}/ca-gregorian`,
      `main/${cldrLocale}/numbers`,
      `main/${cldrLocale}/timeZoneNames`,
    ]);

    setCulture(rcgIndexedLocale);
    this.syncfusionLocaleR.data = rcgIndexedLocale;
    this.deleteStaleLocaleData(localeIndex);
  }

  private async importCldrData(locale: string, paths: string[]) {
    const data = await Promise.allSettled(
      paths.map(async (path) => {
        try {
          const res = await fetch(`${document.baseURI.replace(/\/+$/, '')}/locales/cldr/${path}.json`);
          if (!res.ok) throw await res.text();

          return (await res.json()) as Record<'main' | 'segments' | 'supplemental', { [locale: string]: unknown }>;
        } catch (error) {
          throw { path, error };
        }
      }),
    );

    const fulfilled = data.filter(isSettledPromiseFulfilled);
    const rejected = data.filter(isSettledPromiseRejected);

    for (const r of rejected) {
      console.error('Failed to import cldr data:', r.reason);
    }

    // Replace the locale in the cldr data with the correct one
    const mappedCldrData = fulfilled.map((result) => {
      const typeEntries = Object.entries(result.value).map(([type, cldrData]) => {
        const localeEntries = Object.entries(cldrData).map(([, data]) => [locale, data] as const);

        return [type, Object.fromEntries(localeEntries)] as const;
      });

      return Object.fromEntries(typeEntries);
    });

    loadCldr(...mappedCldrData);
  }

  private deleteStaleLocaleData(currentSfLocaleIndex: number) {
    if (currentSfLocaleIndex > 1) {
      const oldRcgIndexedLocale = `RcG_${currentSfLocaleIndex - 2}`;

      delete L10nLoc.locale[oldRcgIndexedLocale];
      delete typedCldrData.main[oldRcgIndexedLocale];
      delete typedCldrData.default.main[oldRcgIndexedLocale];
    }
  }

  private async setNgLocale(locale: string) {
    const localeRes = await fetch(`${document.baseURI.replace(/\/+$/, '')}/locales/ng/${locale}.mjs`);
    if (!localeRes.ok) throw localeRes.text();

    const localeJS = await localeRes.text();
    const localeJSWithReturn = transformDefaultExportToReturn(localeJS);
    const localeData = loadDynamicJS(localeJSWithReturn, {});

    registerLocaleData(localeData, locale);

    for (const [Pipe, ogTransform, localeArgIndex] of ngPipeTransformations) {
      // @ts-expect-error: We're doing some hacky workarounds here :)
      Pipe.prototype.transform = function (...args) {
        const localizedArgs = [...args];
        localizedArgs[localeArgIndex] = locale;

        return (ogTransform as (...args: unknown[]) => ReturnType<typeof Pipe.prototype.transform>).apply(this, localizedArgs);
      };
    }

    this.applicationRef.tick();
  }

  public switchLocale(locale: string | undefined) {
    this.localeR.data = locale ?? defaultLocale;
  }

  public introspect(enabled: boolean) {
    this.introspectionEnabledR.data = enabled;
  }

  public getTranslation(name: string | undefined, params?: { [name: string]: unknown }) {
    return this.savedTranslationR.value$.pipe(
      map((t) => (name ? t?.translations?.[name] : undefined)),
      map((value) => {
        let val = value;

        if (params && val) {
          for (const [k, v] of Object.entries(params)) {
            val = val.replaceAll(`@${k}`, `${v}`);
          }
        }

        return val;
      }),
      combineLatestWith(this.introspectionEnabled$),
      map(([t, introspect]) => {
        if (!introspect) return t;

        return JSON.stringify({ n: name, p: params, t });
      }),
    );
  }
}
